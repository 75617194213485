import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Switch, withRouter, Redirect } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import * as serviceWorker from './serviceWorker';
import Loadable from 'react-loadable';

import Navigation from './Navigation';

import Footer from './Footer';
// import TopStockholders from './containers/InvestorRelations/TopStockholders';

const Loading = () => <div>Loading...</div>;

//Home
const Homepage = Loadable({
    loader: () => import('./containers/Homepage'),
    loading: Loading,
 });

const HomeRevamp = Loadable({
    loader: () => import('./containers/HomeRevamp'),
    loading: Loading,
 });


 const SearchLandingPage = Loadable({
    loader: () => import('./containers/SearchLandingPage'),
    loading: Loading,
 });

//Media Center
// const Newsroom = Loadable({
//   loader: () => import('./containers/Newsroom'),
//   loading: Loading,
// });

const Newsroom3 = Loadable({
    loader: () => import('./containers/Newsroom3'),
    loading: Loading,
  });

// News
const News = Loadable({
  loader: () => import('./containers/News'),
  loading: Loading,
});

//Media Center Search
//  const NewsroomSearch = Loadable({
//     loader: () => import('./containers/NewsroomSearch'),
//      loading: Loading,
//   });


//Corporate Governance
const CorporateGov = Loadable({
  loader: () => import('./containers/CorpGov/CorpGovLanding'),
  loading: Loading,
});

//Governance Structure - Board of Directors
const BoardofDirectors = Loadable({
  loader: () => import('./containers/CorpGov/BoardofDirectors'),
  loading: Loading,
});

const BODInner = Loadable({
  loader: () => import('./containers/CorpGov/BODInner'),
  loading: Loading,
});

const ExecutiveImplementation = Loadable({
  loader: () => import('./containers/CorpGov/ExecutiveImplementation'),
  loading: Loading,
});

const CGFramework = Loadable({
  loader: () => import('./containers/CorpGov/CGFramework'),
  loading: Loading,
});

const Sitemap = Loadable({
  loader: () => import('./containers/CorpGov/Sitemap'),
  loading: Loading,
});

//Governance Structure - Board Committees
const BoardCommittees = Loadable({
  loader: () => import('./containers/CorpGov/BoardCommittees'),
  loading: Loading,
});

const AbouttheBoard = Loadable({
  loader: () => import('./containers/CorpGov/AbouttheBoard'),
  loading: Loading,
});

//Policies, Processes, Practice - landing
const PoliciesLanding = Loadable({
  loader: () => import('./containers/CorpGov/PoliciesLanding'),
  loading: Loading,
});

const Networking = Loadable({
  loader: () => import('./containers/CorpGov/Networking'),
  loading: Loading,
});

const MonitoringEvaluation = Loadable({
  loader: () => import('./containers/CorpGov/MonitoringEvaluation'),
  loading: Loading,
});

const EducationCommunication = Loadable({
  loader: () => import('./containers/CorpGov/EducationCommunication'),
  loading: Loading,
});

const Disclosures = Loadable({
  loader: () => import('./containers/CorpGov/Disclosures'),
  loading: Loading,
});

const Awards = Loadable({
  loader: () => import('./containers/About/Awards'),
  loading: Loading,
});


//Stakeholders - landing
const Stakeholders = Loadable({
  loader: () => import('./containers/CorpGov/Stakeholders'),
  loading: Loading,
});

const Archives = Loadable({
  loader: () => import('./containers/CorpGov/Archives'),
  loading: Loading,
});

const MVPMessage = Loadable({
  loader: () => import('./containers/CorpGov/MVPMessage'),
  loading: Loading,
});

const ASPMessage = Loadable({
  loader: () => import('./containers/CorpGov/ASPMessage'),
  loading: Loading,
});



const Careers = Loadable({
  loader: () => import('./containers/Careers'),
  loading: Loading,
});

const CareersInner = Loadable({
  loader: () => import('./containers/CareersInner'),
  loading: Loading,
});


const AboutLanding = Loadable({
  loader: () => import('./containers/About/AboutLanding'),
  loading: Loading,
});

const WhatDrivesUs = Loadable({
  loader: () => import('./containers/About/WhatDrivesUs'),
  loading: Loading,
});

const PLDTBusiness = Loadable({
  loader: () => import('./containers/About/PLDTBusiness'),
  loading: Loading,
});

const StakeholderManagement = Loadable({
  loader: () => import('./containers/About/StakeholderManagement'),
  loading: Loading,
});

const SharedValuePrograms = Loadable({
  loader: () => import('./containers/About/SharedValuePrograms'),
  loading: Loading,
});


const CompanyLeadership = Loadable({
  loader: () => import('./containers/About/CompanyLeadership'),
  loading: Loading,
});

const CompanyTimeline = Loadable({
  loader: () => import('./containers/About/CompanyTimeline'),
  loading: Loading,
});

const CorpCitizenship = Loadable({
  loader: () => import('./containers/About/CorpCitizenship'),
  loading: Loading,
});


const Contact = Loadable({
  loader: () => import('./containers/Contact'),
  loading: Loading,
});

//Investor Relations
const InvestorRelations = Loadable({
  loader: () => import('./containers/InvestorRelations/InvestorRelations'),
  loading: Loading,
});

const AnalystInvestors = Loadable({
  loader: () => import('./containers/InvestorRelations/AnalystInvestors'),
  loading: Loading,
});

const AnnualSustainabilityReports = Loadable({
  loader: () => import('./containers/InvestorRelations/AnnualSustainabilityReports'),
  loading: Loading,
});

const CreditRatings = Loadable({
  loader: () => import('./containers/InvestorRelations/CreditRatings'),
  loading: Loading,
});

const DollarBond = Loadable({
  loader: () => import('./containers/InvestorRelations/DollarBond'),
  loading: Loading,
});

const RetailBond = Loadable({
  loader: () => import('./containers/InvestorRelations/RetailBond'),
  loading: Loading,
});

const Presentations = Loadable({
  loader: () => import('./containers/InvestorRelations/Presentations'),
  loading: Loading,
});

const CalendarofEvents = Loadable({
  loader: () => import('./containers/InvestorRelations/CalendarofEvents'),
  loading: Loading,
});

const Podcast = Loadable({
  loader: () => import('./containers/InvestorRelations/Podcast'),
  loading: Loading,
});

const PodcastInner = Loadable({
  loader: () => import('./containers/InvestorRelations/PodcastInner'),
  loading: Loading,
});

const GISArchive = Loadable({
  loader: () => import('./containers/InvestorRelations/GISArchive'),
  loading: Loading,
});


const FinancialReports = Loadable({
  loader: () => import('./containers/InvestorRelations/FinancialReports'),
  loading: Loading,
});


const ShareholdingStructure = Loadable({
  loader: () => import('./containers/InvestorRelations/ShareholdingStructure'),
  loading: Loading,
});

const PublicOwnership = Loadable({
  loader: () => import('./containers/InvestorRelations/PublicOwnership'),
  loading: Loading,
});

const BeneficialOwnership = Loadable({
  loader: () => import('./containers/InvestorRelations/BeneficialOwnership'),
  loading: Loading,
});

const TopStockholders = Loadable({
  loader: () => import('./containers/InvestorRelations/TopStockholders'),
  loading: Loading,
});

const DividendInfo = Loadable({
  loader: () => import('./containers/InvestorRelations/DividendInfo'),
  loading: Loading,
});

const TransferAgents = Loadable({
  loader: () => import('./containers/InvestorRelations/TransferAgents'),
  loading: Loading,
});

const ShareholderInfo = Loadable({
  loader: () => import('./containers/InvestorRelations/ShareholderInformation'),
  loading: Loading,
});

const ShareholderNews = Loadable({
  loader: () => import('./containers/InvestorRelations/ShareholderNews'),
  loading: Loading,
});

const BoardMeeting = Loadable({
  loader: () => import('./containers/InvestorRelations/BoardMeeting'),
  loading: Loading,
});

const FinancialResults = Loadable({
  loader: () => import('./containers/InvestorRelations/FinancialResults'),
  loading: Loading,
});

const PSEClarifications = Loadable({
  loader: () => import('./containers/InvestorRelations/PSEClarifications'),
  loading: Loading,
});

const OrganizationalAnnouncements = Loadable({
  loader: () => import('./containers/InvestorRelations/OrganizationalAnnouncements'),
  loading: Loading,
});

const Sustainability = Loadable({
  loader: () => import('./containers/InvestorRelations/Sustainability'),
  loading: Loading,
});

const Voyager = Loadable({
  loader: () => import('./containers/InvestorRelations/Voyager'),
  loading: Loading,
});

const Acquisitions = Loadable({
  loader: () => import('./containers/InvestorRelations/Acquisitions'),
  loading: Loading,
});

const Capex = Loadable({
  loader: () => import('./containers/InvestorRelations/Capex'),
  loading: Loading,
});

const ShareBuyBack = Loadable({
  loader: () => import('./containers/InvestorRelations/ShareBuyBack'),
  loading: Loading,
});

const TermsofUse = Loadable({
  loader: () => import('./containers/TermsofUse'),
  loading: Loading,
});

const PrivacyNotice = Loadable({
  loader: () => import('./containers/PrivacyNotice'),
  loading: Loading,
});

const CookiePolicy = Loadable({
  loader: () => import('./containers/CookiePolicy'),
  loading: Loading,
});

const PrivacyStatement = Loadable({
  loader: () => import('./containers/PrivacyStatement'),
  loading: Loading,
});

const Error404 = Loadable({
  loader: () => import('./containers/Error404'),
  loading: Loading,
});


const CGPrivacyStatement = Loadable({
  loader: () => import('./containers/CorpGov/CGPrivacyStatement'),
  loading: Loading,
})



const ScrollToTop = withRouter((class extends Component {
  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      window.scrollTo(0, 0)
    }
  }
    render() {
      return this.props.children
    }
  }
))

class PLDTCorpApp extends React.Component {
    render() {
        return (
            <Router>
              <div>
                <Navigation />
                  <ScrollToTop>
                    <Switch>
                      <Route exact path="/cookie-policy" component={CookiePolicy} />
                      <Route exact path="/privacy-policy" component={PrivacyStatement} />
                      <Route exact path="/pldt-smart-customer-champs-privacy-notice" component={PrivacyNotice} />
                      <Route exact path="/terms-of-use" component={TermsofUse} />

                      <Route exact path="/investor-relations/press-releases-and-disclosures/financial-results" component={FinancialResults} />
                      <Route exact path="/investor-relations/press-releases-and-disclosures/news-clarifications-filed-with-pse" component={PSEClarifications} />
                      <Route exact path="/investor-relations/press-releases-and-disclosures/organizational-announcements" component={OrganizationalAnnouncements} />
                      <Route exact path="/investor-relations/press-releases-and-disclosures/sustainability" component={Sustainability} />
                      <Route exact path="/investor-relations/press-releases-and-disclosures/voyager-paymaya" component={Voyager} />
                      <Route exact path="/investor-relations/press-releases-and-disclosures/capex-related-disclosures" component={Capex} />
                      <Route exact path="/investor-relations/press-releases-and-disclosures/acquisitions-partnerships-and-others" component={Acquisitions} />
                      <Route exact path="/investor-relations/press-releases-and-disclosures/share-buy-back-disclosures" component={ShareBuyBack} />
                      <Route exact path="/investor-relations/press-releases-and-disclosures" component={BoardMeeting} />

                      <Route exact path="/investor-relations/shareholder-information/transfer-agents" component={TransferAgents} />
                      <Route exact path="/investor-relations/shareholder-information/dividend-info" component={DividendInfo} />
                      <Route exact path="/investor-relations/shareholder-information/top-100-stockholders" component={TopStockholders} />
                      <Route exact path="/investor-relations/shareholder-information/beneficial-ownership" component={BeneficialOwnership} />
                      <Route exact path="/investor-relations/shareholder-information/public-ownership-report" component={PublicOwnership} />
                      <Route exact path="/investor-relations/shareholder-information/shareholding-structure" component={ShareholdingStructure} />

                      <Route exact path="/investor-relations/shareholder-information/latest-shareholders-news/gis-archive" component={GISArchive} />
                      <Route path="/investor-relations/shareholder-information/latest-shareholders'-news/gis-archive"><Redirect to="/investor-relations/shareholder-information/latest-shareholders-news/gis-archive" /></Route>

                      <Route exact path="/investor-relations/shareholder-information/latest-shareholders-news" component={ShareholderNews} />

                      <Route path="/investor-relations/shareholder-information/latest-shareholders'-news"><Redirect to={`/investor-relations/shareholder-information/latest-shareholders-news#${window.location.hash.substring(1)}`} /></Route>

                      <Route exact path="/investor-relations/shareholder-information" component={ShareholderInfo} />

                      <Route exact path="/investor-relations/podcasts/:nodeid" component={PodcastInner} />
                      <Route exact path="/investor-relations/podcasts" component={Podcast} />
                      <Route exact path="/investor-relations/calendar-of-events" component={CalendarofEvents} />
                      <Route exact path="/investor-relations/presentations" component={Presentations} />
                      <Route exact path="/investor-relations/financial-results" component={FinancialReports} />
                      <Route exact path="/investor-relations/credit-ratings-reports" component={CreditRatings} />
                      <Route exact path="/investor-relations/pldt-u-s-dollar-bond" component={DollarBond} />
                      <Route exact path="/investor-relations/pldt-retail-bond" component={RetailBond} />
                      <Route exact path="/investor-relations/annual-and-sustainability-reports" component={AnnualSustainabilityReports} />
                      <Route exact path="/investor-relations/analysts-and-investors-contact" component={AnalystInvestors} />
                      <Route exact path="/investor-relations" component={InvestorRelations} />

                      <Route exact path="/about-us/awards-and-citations" component={Awards} />
                      <Route exact path="/about-us/corporate-citizenship/stakeholder-management" component={StakeholderManagement} />
                      <Route exact path="/about-us/corporate-citizenship/shared-value-programs" component={SharedValuePrograms} />
                      <Route exact path="/about-us/corporate-citizenship" component={CorpCitizenship} />
                      <Route exact path="/about-us/company-timeline" component={CompanyTimeline} />
                      <Route exact path="/about-us/company-leadership" component={CompanyLeadership} />

                      <Route exact path="/about-us/pldts-business" component={PLDTBusiness} />
                      <Route path="/about-us/pldt's-business"><Redirect to="/about-us/pldts-business" /></Route>

                      <Route exact path="/about-us/what-drives-us" component={WhatDrivesUs} />
                      <Route exact path="/about-us" component={AboutLanding} />

                      <Route exact path="/contact-us" component={Contact} />
                      <Route exact path="/careers/:employeeid" component={CareersInner} />
                      <Route exact path="/careers" component={Careers} />

                      <Route exact path="/corporate-governance-in-pldt/privacy-notice" component={CGPrivacyStatement} />
                      <Route exact path="/corporate-governance-in-pldt/message-from-the-president-and-ceo" component={ASPMessage} />
                      <Route exact path="/corporate-governance-in-pldt/message-from-the-chairman" component={MVPMessage} />
                      <Route exact path="/corporate-governance-in-pldt/archives" component={Archives} />
                      <Route exact path="/corporate-governance-in-pldt/our-stakeholders" component={Stakeholders} />

                      <Route exact path="/corporate-governance-in-pldt/policies-processes-and-practices/disclosures" component={Disclosures} />
                      <Route exact path="/corporate-governance-in-pldt/policies-processes-and-practices/monitoring-and-evaluation" component={MonitoringEvaluation} />
                      <Route exact path="/corporate-governance-in-pldt/policies-processes-and-practices/networking" component={Networking} />
                      <Route exact path="/corporate-governance-in-pldt/policies-processes-and-practices/education-and-communication" component={EducationCommunication} />
                      <Route exact path="/corporate-governance-in-pldt/policies-processes-and-practices" component={PoliciesLanding} />
                      <Route exact path="/corporate-governance-in-pldt/governance-structures/cg-framework" component={CGFramework} />
                      <Route exact path="/corporate-governance-in-pldt/governance-structures/executive-implementation" component={ExecutiveImplementation} />
                      <Route exact path="/corporate-governance-in-pldt/governance-structures/about-the-board" component={AbouttheBoard} />
                      <Route exact path="/corporate-governance-in-pldt/governance-structures/board-committees" component={BoardCommittees} />
                      <Route exact path="/corporate-governance-in-pldt/governance-structures/director/:directorid" component={BODInner} />
                      <Route exact path="/corporate-governance-in-pldt/governance-structures" component={BoardofDirectors} />
                      <Route exact path="/corporate-governance-in-pldt/site-map" component={Sitemap} />
                      <Route exact path="/corporate-governance-in-pldt" component={CorporateGov} />

                      <Route exact path="/article/:nodeid" component={News} />
                      {/* <Route exact path="/newsroom/search" component={NewsroomSearch} /> */}
                      <Route exact path="/newsroom" component={Newsroom3} />
                      {/* <Route exact path="/newsroom-revamp" component={Newsroom} /> */}


                      <Route exact path="/" component={HomeRevamp} />
                      <Route exact path="/search" component={SearchLandingPage} />
                      <Route path="*" component={Error404} />
                    </Switch>
                  </ScrollToTop>
                <Footer/>
              </div>
            </Router>
        )
    }
}



ReactDOM.render(<PLDTCorpApp />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
